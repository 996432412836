<template>
	<van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
		<div class="record-detail" style="min-height: 100vh;" ref="listView">
			<div class="container" v-if="detail">
				<div class="top-box">
					<div class="head">
						<div class="clinic-name" v-text="detail.registerInfo.clinicname"></div>
						<div class="user">
							<span class="name" v-text="detail.registerInfo.clientname"></span>
							/
							<span class="info" v-text="detail.registerInfo.diagnosis"></span>
						</div>
						<div class="create-time" v-text="detail.registerInfo.registerdate"></div>
					</div>
					<div :class="{'note': true, 'hide': ! detail.registerInfo.doctoradvice}">
						<div class="hint">医嘱:</div>
						<div class="text" v-text="detail.registerInfo.doctoradvice"></div>
					</div>
				</div>
				<div class="item" v-for="(item, index) in detail.prescriptionInfo" :key="index">
					<div class="title" v-text="item.prescriptionSeqName + '(單位'+item.medicinalTypeName+')'"></div>
					<div class="list" :style="{'grid-template-rows': Array.from(new Array(Math.floor(item.listMedicinal.length / 2)).keys()).map((d)=>'1fr').join(' ') }">
						<div class="list-item" v-for="(d, i) in item.listMedicinal" :key="index+'_'+i">
							<span class="n" v-text="d.medicinalName"></span>
							<span class="v" v-text="d.medicinalWeight.toFixed(2)+'克'"></span>
						</div>
					</div>
					<div class="other">
						<span v-text="item.takeTime"></span>
						<span v-text="'每日'+item.takebyday+'次'"></span>
						<span v-text="'用藥'+item.dosedays+'天'"></span>
					</div>
				</div>
			</div>
			<loading v-if="loading" />
		</div>
	</van-pull-refresh>
</template>

<script>
	import Loading from '@/layouts/Loading.vue'
	export default {
		components: {
			Loading
		},
		data(){
			return {
				detail: null,
				loading: false,
				isRefresh: false,
			}
		},
		mounted: function(){
			this.onRefresh()
		},
		methods: {
			onRefresh: function(){
				this.loading = true
				this.isRefresh = false
				this.$store.dispatch('getMobileRecordDetail', {
					clientId: this.$store.getters.clientId,
					clientregisterid: this.$route.params.id,
				}).then((data)=>{
					this.loading = false
					this.detail = data
				}).catch((error)=>{
					this.loading = false
					this._toast.warning(this, error)
				})
			}
		},
	}
</script>

<style lang="scss" scoped>
	.record-detail {
		background-color: #F6F6F6;
		display: flex;
		flex-direction: column;
		margin-top: 20px;
	}

	.container {
		display: flex;
		flex-direction: column;
	}

	.record-detail .top-box {
		background-color: #fff;
	}

	.record-detail .head {
		position: relative;
		display: flex;
		flex-direction: column;
		margin: 16px 13px;
		box-sizing: border-box;
		padding: 35px 30px;
		border: 1px solid #f7f7f7;
		box-shadow: 2px 2px 3px #eee;
		border-radius: 4px;
	}

	.record-detail .head .clinic-name {
		color: #202020;
		font-size: 34px;
		font-weight: bold;
	}

	.record-detail .head .create-time {
		position: absolute;
		right: 30px;
		top: 41px;
		color: #9A9A9A;
		font-weight: 400;
		font-size: 26px;
	}

	.record-detail .head .user {
		display: flex;
		flex-direction: row;
		font-size: 30px;
		font-weight: 400;
		color: #343434;
		margin-top: 20px;
	}

	.record-detail .head .user > .info {
		color: #FF6A22;
		margin-left: 6px;
	}

	.record-detail .top-box .note {
		display: flex;
		flex-direction: row;
		margin: 0px 23px;
	}

	.record-detail .top-box .note.hide {
		display: none;
	}

	.record-detail .top-box .note > .hint {
		color: #9A9A9A;
		font-size: 24px;
		font-weight: 400;
	}

	.record-detail .top-box .note > .text {
		color: #202020;
		font-size: 24px;
		font-weight: bold;
		margin-left: 10px;
		flex: 1;
		margin-bottom: 33px;
	}

	.record-detail .item {
		margin-top: 16px;
		background-color: #fff;
		padding: 32px 24px;
	}

	.record-detail .item > .other {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		font-size: 28px;
		font-weight: 400;
		color: #FF6A22;
	}

	.record-detail .item > .other > span {
		margin-left: 87px;
	}

	.record-detail .item > .title {
		color: #202020;
		font-weight: bold;
		font-size: 30px;
		margin-bottom: 39px;
	}

	.record-detail .item .list {
		background-color: #fff;
		display: grid;
		grid-auto-columns: 1fr;
		grid-auto-flow: column;
		margin-bottom: 40px;
        grid-row-gap: 2px;
        grid-column-gap: 2px;
	}

	.record-detail .item .list > .list-item {
		background-color: #EEEEEE;
		height: 56px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		box-sizing: border-box;
		padding: 0px 16px;
	}

	.record-detail .item .list > .list-item > .n {
		color: #202020;
		font-weight: bold;
		font-size: 30px;
	}

	.record-detail .item .list > .list-item > .v {
		color: #505050;
		font-size: 30px;
		font-weight: 400;
	}
</style>
